<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn
        block
        color="secondary"
        @click="onShowDialog"
        v-on="on"
        data-test="activateButton"
        data-cy="user-profile-cp-button"
      >
        Change Password
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="mb-5">
        <span class="headline font-weight-bold primary--text"
          >Change Password</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <small class="red--text">* indicates required field</small>
          </v-row>
          <v-form lazy-validation ref="form">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="New Password *"
                  name="password"
                  prepend-icon="mdi-lock"
                  v-model="password"
                  :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="togglePassword = !togglePassword"
                  :type="togglePassword ? 'text' : 'password'"
                  :rules="rules.requireCheck.concat(rules.passwordCheck)"
                  data-cy="user-profile-change-password"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Confirm New Password *"
                  name="confirmPassword"
                  prepend-icon="mdi-lock-check"
                  v-model="confirmPassword"
                  :append-icon="toggleConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="toggleConfirm = !toggleConfirm"
                  :type="toggleConfirm ? 'text' : 'password'"
                  :rules="rules.requireCheck.concat(rules.passwordRule)"
                  data-cy="user-profile-change-confirm"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider />
        <v-row class="ma-2">
          <v-btn color="warning" @click="onClose" data-test="cancelButton" data-cy="user-profile-cp-cancel-button"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onSave" data-test="saveButton" data-cy="user-profile-cp-save-button"
            >Save</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ChangePasswordForm",
  props: {
    name: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      password: null,
      confirmPassword: null,
      togglePassword: false,
      toggleConfirm: false,
      dialog: false,
      rules: {
        requireCheck: [(v) => !!v || "Input is required"],
        passwordCheck: [
          (v) =>
            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*(){}<>?]).{8,}/.test(
              v
            ) ||
            "Password must contain uppercase and lowercase letters, a number and a special character.",
        ],
        passwordRule: [
          (v) => v === this.password || "Password does not match.",
        ],
      },
    };
  },
  computed: {},
  methods: {
    onShowDialog() {
      this.formName = this.name;
      this.formEmail = this.email;
    },
    onClose() {
      this.$refs.form.reset();
      this.dialog = false;
    },
    onSave() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const actionPayload = {
        new_password1: this.password,
        new_password2: this.confirmPassword,
      };
      this.$emit("savePassword", actionPayload);
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
};
</script>
